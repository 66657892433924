
import { mapGetters } from 'vuex'

export default {
  props: {
    banners: {
      required: true,
      default () {
        return {}
      },
      type: Array
    },
    currency: {
      required: true,
      default () {
        return {}
      },
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      settings: 'settings'
    }),

    link () {
      return {
        name: 'slug',
        params: {
          slug: this.banner.bannerable.page.slug
        }
      }
    }
  },

  methods: {
    generateLink (slug) {
      if (slug.includes('/')) {
        const slugs = slug.split('/')

        return this.localePath({
          name: 'slug-params',
          params: {
            slug: slugs[0],
            params: slugs[1]
          }
        })
      } else {
        return this.localePath({ name: 'slug', params: { slug } })
      }
    },

    background (banner) {
      if (this.$mq === 'fullhd' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.fullhd
      } else if (this.$mq === 'widescreen' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.widescreen
      } else if (this.$mq === 'desktop' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.desktop
      } else if (this.$mq === 'tablet' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.tablet
      } else if (this.$mq === 'mobile' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.mobile
      } else {
        return banner.backgrounds.mobileSmall
      }
    }
  }
}
