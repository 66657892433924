
import location from '@/mixins/location.js'

export default {
  mixins: [
    location
  ],

  props: {
    center: {
      required: true,
      default () {
        return {
          lat: 0,
          lng: 0
        }
      },
      type: Object
    },
    locations: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      closest: '',
      locationsVisibleOnMap: '',
      zoom: 18,
      isMounted: false,
      pins: {
        selected:
          `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='${this.$config.secondaryColor}' stroke='${this.$config.secondaryColor}' stroke-width='1px' d='M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z'/%3E%3C/svg%3E`,
        notSelected:
          `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath fill='${this.$config.primaryColor}' stroke='${this.$config.secondaryColor}' d='M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z'/%3E%3C/svg%3E`
      },
      options: {
        fullscreenControl: true,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: true,
        gestureHandling: 'cooperative',
        styles: [
          //
        ]
      },
      cluster: {
        options: {
          styles: [
            {
              url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
              width: 56,
              height: 56,
              textColor: '#fff'
            }
          ]
        }
      }
    }
  },

  watch: {
    center: {
      handler (val, oldVal) {
        this.$refs.gMap.map.setCenter(val)
      },
      deep: true
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
    })
  },

  methods: {
    generateSchedule (contact) {
      const schedule = []
      const vm = this

      if (contact.schedule.holiday) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday() - 1

        contact.schedule.days.forEach((day, index) => { day.index = index })

        contact.schedule.days.slice(weekday).concat(contact.schedule.days.slice(0, weekday)).forEach((day) => {
          const i = schedule.findIndex((x, key) => x.open === day.open && x.close === day.close && key === schedule.length - 1)

          if (i === -1) {
            schedule.push({
              days: vm.$moment(day.index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase(),
              open: day.open,
              close: day.close
            })
          } else {
            schedule[i].days = `${schedule[i].days.split('–')[0]}–${vm.$moment(day.index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase()}`
          }
        })
      } else {
        contact.schedule.days.forEach(function (item, index) {
          const i = schedule.findIndex((x, key) => x.open === item.open && x.close === item.close && key === schedule.length - 1)

          if (i === -1) {
            schedule.push({
              days: vm.$moment(index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase(),
              open: item.open,
              close: item.close
            })
          } else {
            schedule[i].days = `${schedule[i].days.split('–')[0]}–${vm.$moment(index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase()}`
          }
        })
      }

      return schedule
    },

    checkForMarkers () {
      this.locations.forEach((location, i) => {
        location.visible = this.$refs.gMap.map
          .getBounds()
          .contains(this.$refs.gMap.markers[i].getPosition())
      })

      this.locationsVisibleOnMap = this.locations
        .filter(l => l.visible)
        .map(l => l.name)
        .join(', ')
    }
  }
}
