
export default {
  props: {
    form: {
      required: true,
      default () {
        return {
          method: '',
          action: '',
          inputs: []
        }
      },
      type: Object
    }
  }
}
