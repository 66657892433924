
import Slide from '@/components/index/Slide'

export default {
  components: {
    Slide
  },

  props: {
    slides: {
      required: true,
      default () {
        return []
      },
      type: Array
    },
    currency: {
      required: true,
      default () {
        return {}
      },
      type: Object
    }
  },

  data () {
    return {
      isMounted: false,
      swiperOption: {
        lazy: true,
        // loop: this.slides.length > 1,
        allowTouchMove: this.slides.length > 1,
        slidesPerView: 'auto',
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        on: {
          slideChange () {
            // console.log('onSlideChangeEnd', this)
          },
          tap () {
            // console.log('onTap', this.realIndex)
          }
        }
      }
    }
  },

  mounted () {
    if (this.slides.length > 1) {
      this.slideChange()
    }

    this.isMounted = true
  },

  methods: {
    prev () {
      this.indexSwiper.slideTo(this.indexSwiper.activeIndex - 1)
    },

    next () {
      this.indexSwiper.slideTo(this.indexSwiper.activeIndex + 1)
    },

    slideChange () {
      const background = this.slides[this.indexSwiper.realIndex].backgrounds[this.$mq]
      const color = this.slides[this.indexSwiper.realIndex].color

      const arrow = {
        color: ['color'].includes(background.type) ? background.style.background : color.value
      }

      this.$refs.indexSwiperPrev.style.color = arrow.color
      this.$refs.indexSwiperPrev.style.backgroundColor = color.contrast

      this.$refs.indexSwiperNext.style.color = arrow.color
      this.$refs.indexSwiperNext.style.backgroundColor = color.contrast
    }
  }
}
