
import { mapGetters } from 'vuex'

import Banner from '@/components/index/Banner'

export default {
  components: {
    Banner
  },

  props: {
    banners: {
      type: Array,
      default () {
        return []
      },
      required: true
    }
  },

  computed: {
    ...mapGetters({
      currency: 'currency',
      country: 'country',
      logo: 'logo',
      settings: 'settings',
      recent: 'recent/recent',
      sale: 'sale/products',
      journal: 'journal/index',
      menus: 'menus',
      store: 'store',
      popupAd: 'modal/popupAd'
    })
  }
}
