
export default {
  props: {
    article: {
      required: true,
      default () {
        return {}
      },
      type: Object
    }
  },

  computed: {
    link () {
      return {
        name: 'slug',
        params: {
          slug: this.article.page.slug
        }
      }
    }
  }
}
