
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    }
  },

  data () {
    return {
      active: false,
      items: [],
      city: this.value,
      error: '',
      query: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      store: 'store'
    })
  },

  watch: {
    value: {
      handler (val) {
        this.city = val
      },
      immediate: true
    },

    search: {
      handler (val) {
        this.loading = true

        this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/stores/shop/cities`)
          .then((res) => {
            this.items = res.data
          })
          .catch((error) => {
            this.$notifier.showMessage({ content: error, color: 'error' })
          })
          .finally(() => (this.loading = false))
      },
      immediate: true,
      deep: true
    }
  },

  mounted () {
    this.getCities()
  },

  methods: {
    getCities () {
      this.loading = true

      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/stores/shop/cities`)
        .then((res) => {
          this.items = res.data
        })
        .catch((error) => {
          this.$notifier.showMessage({ content: error, color: 'error' })
        })
        .finally(() => (this.loading = false))
    },

    hideCityDropDown () {
      this.active = false
    },

    toggleCitySelect () {
      this.active = !this.active

      this.$nextTick(() => {
        this.$refs.city.focus()
      })
    },

    changeCity (city) {
      this.$emit('change', city)
      this.active = false
    }
  }
}
